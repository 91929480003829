import './style.scss';
import {Carousel, Slide} from 'vue-carousel';

const init = () => {
  document.querySelectorAll('.vue-carousel-container').forEach((el) => {
    new Vue({
      el,
      components: {
        Carousel,
        Slide,
      },
    });
  });
};

init();

document.addEventListener('initVueCarousel', init);
