// модуль для отоженной загрузки слайдера с акциями компании

import {fetchData} from '@apress/apress-frontend-utils/utils/fetch-data/fetch-data.js';

app.modules.ajaxCompanyOffers = ((self = {}) => {
  const OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  let _target;

  const _observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        _getCompanyOffers().then((offers) => {
          if (!offers) return;

          _renderCompanyOffers(offers);
        });
        observer.unobserve(entry.target);
      }
    });
  }, OBSERVER_OPTIONS);

  const _getCompanyOffers = () => {
    return fetchData({
      url: _target.dataset.companyOffersUrl,
      responseType: 'text',
      isXMLHttp: true,
    });
  };

  const _renderCompanyOffers = (offers) => {
    _target.innerHTML = offers;

    if (app.config.isMobile) {
      app.modules.imagesLazyLoad && app.modules.imagesLazyLoad.load();
      return;
    }

    document.dispatchEvent(new CustomEvent('initVueCarousel'));
  };

  const _init = () => {
    _target = document.querySelector('.js-company-offers-container');

    if (!_target) return;

    _observer.observe(_target);
  };

  self.load = _init;
  return self;
})(app.modules.ajaxCompanyOffers);
