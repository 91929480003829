// Модуль слайдера на главной СК

import Swiper from 'swiper';

app.modules.companySlider = (function (self) {
  function _initSlider() {
    const swiperEl = document.querySelector('.js-company-slider-new');

    swiperEl.classList.remove('unactivated');

    new Swiper(swiperEl, {
      slideClass: 'company-slider__slide-item',
      wrapperClass: 'company-slider__swiper-wrapper',
      slidesPerView: 'auto',
      spaceBetween: 6,
      pagination: {
        el: '.js-company-slider-pagination',
        clickable: true,
        bulletClass: 'company-slider__pagination-bullet',
        bulletActiveClass: 'company-slider__pagination-bullet_active',
        lockClass: 'company-slider__pagination_lock',
      },
      navigation: {
        nextEl: '.js-company-slider-next',
        prevEl: '.js-company-slider-prev',
        lockClass: 'company-slider__arrow_lock',
      },
      loop: _setSliderLoop(),
      autoplay: _setSliderInterval(),
      watchOverflow: true,
    });
  }

  function _setSliderInterval() {
    if (app.config.sliderInterval) {
      return {delay: app.config.sliderInterval};
    }
  }

  function _addListeners() {
    $doc.on('click', '.js-company-slider-hide', function () {
      $('.js-company-slider-new').hide();
    });
  }

  function _setSliderLoop() {
    const slideCount = document.querySelectorAll(
      '.js-company-slider-item'
    ).length;
    const sliderCapacity =
      document.querySelector('.js-company-slider-new').offsetWidth /
      document.querySelector('.js-company-slider-item').offsetWidth;

    return slideCount > sliderCapacity;
  }

  self.load = function () {
    if (!document.querySelector('.js-company-slider-new')) {
      return;
    }
    _initSlider();
    _addListeners();
  };

  return self;
})(app.modules.swiper || {});
